import { ToolBarChip } from './tool-bar-chip.model';
import { ToolBarButton } from './tool-bar-button.model';

type Constructor<T = {}> = new (...args: any[]) => T;

export function toolBarPageMixin<T extends Constructor<{}>>(
    Base: T = class {} as any
) {
    return class extends Base {
        toolBarChips: ToolBarChip[] = [];
        toolBarButtons: ToolBarButton[] = [];

        // Clear the tool bar.
        clearToolBar() {
            this.toolBarChips = [];
            this.toolBarButtons = [];
        }

        // Add a header button.
        addToolBarButton(button: ToolBarButton) {
            this.toolBarButtons.push(button);
        }

        // Add multiple header buttons.
        addToolBarButtons(buttons: ToolBarButton[]) {
            this.toolBarButtons.push(...buttons);
        }

        // Find the button with the given ID.
        findToolBarBtn(id: string) {
            return this.toolBarButtons.find((b) => b.id == id);
        }

        // Find the chip with the given ID.
        findToolBarChip(id: string) {
            return this.toolBarChips.find((b) => b.id == id);
        }

        // Update a item's property.
        updateToolBar(id, property, value) {
            let btn: ToolBarButton | ToolBarChip = this.findToolBarBtn(id);
            btn ??= this.findToolBarChip(id);
            if (btn) {
                btn[property] = value;
            }
        }
    };
}

export const ToolBarPageMixin = toolBarPageMixin();
